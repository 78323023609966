<template>
  <div>
    <!-- begin:: Header -->
    <div
      id="kt_header"
      ref="kt_header"
      class="header"
      v-bind:class="headerClasses"
    >
      <div
        class="container-fluid d-flex align-items-center justify-content-between"
      >
        <!-- begin:: Header Menu -->
        <div
          class="header-menu-wrapper header-menu-wrapper-left"
          ref="kt_header_menu_wrapper"
        >
          <div
            v-if="headerMenuEnabled"
            id="kt_header_menu"
            ref="kt_header_menu"
            class="header-menu header-menu-mobile"
            v-bind:class="headerMenuClasses"
          >
            <!-- example static menu here -->
            <ul
              class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2"
            >
              <template>
                <li class="breadcrumb-item">
                  <span style="font-size: 12px;">
                    OPERASIONAL / FORMULIR PENGAJUAN / <span style="font-weight: 600;">ADMINISTRASI</span>
                  </span>
                </li>
              </template>
            </ul>
          </div>
        </div>
        <!-- end:: Header Menu -->
        <!-- begin:: Header Topbar -->
        <div class="topbar">
          <!--begin: User Bar -->
          <div class="topbar-item">
            <!--begin: Notifications -->
            <ApprovalNotification></ApprovalNotification>
            <!--end: Notifications -->
            <div
              class="btn btn-icon w-auto d-flex align-items-center btn-lg px-2"
              id="kt_quick_user_toggle"
            >
              <span
                class="symbol symbol-30 symbol-circle symbol-xl-30 pr-4 symbol-light-success"
              >
                <img v-if="false" alt="Profile Photo" :src="currentUserPhoto" />
                <span
                  v-if="true"
                  class="symbol-label font-size-h5 font-weight-bold"
                >
                  {{ user_fullname.charAt(0).toUpperCase() }}
                </span>
              </span>
              <span
                class="d-none d-md-inline mr-12"
                v-bind:style="{
                  'font-family': 'lato',
                  'font-size': '12px',
                  'font-weight': '600'
                }"
              >
                <span class="font-weight-bold font-size-base d-md-inline mr-1">
                  Hi,
                </span>
                {{ user_fullname }}
              </span>
              <i class="ki ki-arrow-down icon-xs text-muted mr-12"></i>
            </div>

            <div
              id="kt_quick_user"
              ref="kt_quick_user"
              class="offcanvas offcanvas-right p-10"
            >
              <!--begin::Header-->
              <div
                class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
              >
                <h3 class="font-weight-bold m-0">
                  PROFIL USER
                </h3>
                <a
                  href="#"
                  class="btn btn-xs btn-icon btn-light btn-hover-primary"
                  id="kt_quick_user_close"
                >
                  <i class="ki ki-close icon-xs text-muted"></i>
                </a>
              </div>
              <!--end::Header-->

              <!--begin::Content-->
              <perfect-scrollbar
                class="offcanvas-content pr-5 mr-n5 scroll"
                style="max-height: 90vh; position: relative;"
              >
                <!--begin::Header-->
                <div class="d-flex align-items-center mt-5">
                  <div class="symbol symbol-100 mr-5">
                    <img :src="currentUserPersonalInfo.photo" alt="" />
                    <i class="symbol-badge bg-success"></i>
                  </div>
                  <div class="d-flex flex-column">
                    <span class="font-weight-bold font-size-h5 text-dark-75">
                      {{ user_fullname }}
                    </span>
                    <div class="navi mt-2">
                      <span class="navi-link p-0 pb-2">
                        <span class="navi-icon mr-1">
                          <span class="svg-icon svg-icon-lg svg-icon-primary">
                            <!--begin::Svg Icon-->
                            <inline-svg src="assets/icons/Mail.svg" />
                            <!--end::Svg Icon-->
                          </span>
                        </span>
                        <span class="navi-text text-muted">
                          {{ email }}
                        </span>
                      </span>
                    </div>
                    <button
                      class="btn btn-light-primary btn-bold"
                      @click="onLogout"
                    >
                      SIGN OUT
                    </button>
                  </div>
                </div>
                <!--end::Header-->
                <div class="separator separator-dashed mt-8 mb-5"></div>
                <div class="separator separator-dashed my-7"></div>
              </perfect-scrollbar>
              <!--end::Content-->
            </div>
          </div>
          <!--end: User Bar -->
        </div>
        <!-- end:: Header Topbar -->
      </div>
    </div>
    <!-- end:: Header -->
    <!-- Body -->
    <div style="margin-bottom: 43px;">
      <div>
        <img class="mb-4" src="assets/background_page_title.png" alt="" style="height: 36px; margin-right: 10px;">
        <span style="font-size: 30px; font-weight: 700;">
          ADMINISTRASI
        </span>
      </div>
      <div>
        <span>
          MENAMPILKAN DAN MEMBUAT DATA TRANSAKSI ADMINISTRASI BARU
        </span>
      </div>
    </div>
    <div class="form-group">
      <template>
        <v-toolbar flat>
          <v-dialog v-model="dialog" max-width="800px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="white"
                light
                class="mb-12"
                v-bind="attrs"
                v-on="on"
                style="text-transform: capitalize !important; min-height: 48px;"
              >
                BUAT FORM BARU +
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-col>
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;"
                          >PERUSAHAAN<span style="color: red;">*</span></span
                        >
                      </v-col>
                      <v-col cols="9">
                        <v-select
                          v-model="formInput.workgroup_id"
                          id="workgroup_id"
                          :items="data_workgroup_combobox"
                          @click="dataWorkgroup_Combobox"
                          item-value="workgroup_id"
                          item-text="workgroup_name"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;"
                          >TANGGAL TRANSAKSI<span style="color: red;"
                            >*</span
                          ></span
                        >
                      </v-col>
                      <v-col cols="9">
                        <v-menu
                          ref="date_submission_form"
                          v-model="date_submission_form"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          full-width
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="formInputNotClear.date_submission"
                              class="vuetify-custom"
                              solo
                              v-bind:style="{
                                color: '#c30e0e !important'
                              }"
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="date_submission"
                            no-title
                            @input="date_submission_form = false"
                            @change="onChangeDate(date_submission)"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;"
                          >UNIT MATRA<span style="color: red;">*</span></span
                        >
                      </v-col>
                      <v-col cols="9">
                        <v-select
                          v-model="formInput.organizational_unit_id"
                          id="organizational_unit_id"
                          :items="data_organizational_units_combobox"
                          @click="dataOrganizationalUnits_Combobox"
                          item-value="organizational_unit_id"
                          item-text="organizational_unit_name"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;">
                          SUB UNIT MATRA<span style="color: red;">*</span>
                        </span>
                      </v-col>
                      <v-col cols="9">
                        <v-select
                          v-model="formInput.sub_organizational_unit"
                          id="sub_organizational_unit_id"
                          :items="data_sub_organizational_unit_combobox"
                          @change="
                            titleFromSubOrganizationalUnit(
                              formInput.sub_organizational_unit
                            )
                          "
                          item-value="sub_organizational_unit_id"
                          item-text="sub_organizational_unit_name"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{ color: '#1e2758 !important' }"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <div v-if="seenComboboxWorkUnit == 1">
                      <v-row>
                        <v-col cols="3" class="mt-4">
                          <span style="color: black; font-size: 14px;"
                            >{{ title_select
                            }}<span style="color: red;">*</span></span
                          >
                        </v-col>
                        <v-col cols="9">
                          <v-select
                            v-model="formInput.work_unit_id"
                            id="work_unit_id"
                            :items="data_work_units_combobox"
                            @click="dataWorkUnits_Combobox"
                            @change="itemWorkUnits(formInput.work_unit_id)"
                            item-value="work_unit_id"
                            item-text="work_unit_name"
                            class="vuetify-custom"
                            solo
                            v-bind:style="{
                              color: '#1e2758 !important'
                            }"
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="3" class="mt-4">
                          <span style="color: black; font-size: 14px;">
                            SUB SATUAN
                            <span style="color: red;">*</span>
                          </span>
                        </v-col>
                        <v-col cols="9">
                          <v-select
                            v-model="formInput.sub_work_unit_id"
                            id="sub_work_unit_id"
                            :items="data_sub_work_units_combobox"
                            @click="dataSubWorkUnits_Combobox"
                            item-value="sub_work_unit_id"
                            item-text="sub_work_unit_name"
                            solo
                            v-bind:style="{
                              color: '#1e2758 !important'
                            }"
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row v-if="formInput == add_submission_form">
                        <v-col cols="3" class="mt-4">
                          <span style="color: black; font-size: 14px;">
                            BAGIAN SUB SATUAN
                            <span style="color: red;">*</span>
                          </span>
                        </v-col>
                        <v-col cols="9">
                          <v-select
                            v-model="formInput.part_of_sub_work_unit_id"
                            id="part_of_sub_work_unit_id"
                            :items="data_part_of_sub_work_units_combobox"
                            @click="dataPartOfSubWorkUnits_Combobox"
                            item-value="part_of_sub_work_unit_id"
                            item-text="part_of_sub_work_unit_name"
                            multiple
                            solo
                            v-bind:style="{
                              color: '#1e2758 !important'
                            }"
                          >
                            <template v-slot:prepend-item>
                              <v-list-item
                                ripple
                                @mousedown.prevent
                                @click="toggle"
                              >
                                <v-list-item-action>
                                  <v-icon
                                    :color="
                                      formInput.part_of_sub_work_unit_id
                                        .length > 0
                                        ? 'indigo darken-4'
                                        : ''
                                    "
                                  >
                                    {{ icon }}
                                  </v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    PILIH SEMUA
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </template>
                          </v-select>
                        </v-col>
                      </v-row>
                    </div>
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;"
                          >PROYEK<span style="color: red;">*</span></span
                        >
                      </v-col>
                      <v-col cols="9">
                        <v-select
                          v-model="formInput.project_id"
                          id="project_id"
                          :items="data_project_combobox"
                          @click="dataProject_Combobox"
                          item-value="project_id"
                          item-text="project_name"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <!-- <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;">
                          Kegiatan<span style="color: red;">*</span>
                        </span>
                      </v-col>
                      <v-col cols="9">
                        <v-select
                          v-model="formInput.activity_id"
                          id="activity_id"
                          :items="data_activities_combobox"
                          @click="dataActivities_Combobox"
                          item-value="activity_id"
                          item-text="activity_name"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                        ></v-select>
                      </v-col>
                    </v-row> -->
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;">DESKRIPSI TRANSAKSI</span>
                      </v-col>
                      <v-col cols="9">
                        <v-textarea
                          v-model="formInput.submission_desc"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                        >
                        </v-textarea>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;">
                          STATUS PEMBAYARAN<span style="color: red;">*</span>
                        </span>
                      </v-col>
                      <v-col cols="9">
                        <v-select
                          v-model="formInput.payment_status"
                          id="payment_status_id"
                          :items="data_payment_status_combobox"
                          @change="seenDetailPaymentStatus"
                          item-value="payment_status_id"
                          item-text="payment_status_name"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{ color: '#1e2758 !important' }"
                        ></v-select>
                        <v-row v-if="seen === 'Lain-lain'">
                          <v-col cols="9">
                            <v-text-field
                              v-model="formInputPaymentStatus.payment_status"
                              class="vuetify-custom"
                              solo
                              v-bind:style="{
                                color: '#1e2758 !important'
                              }"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row v-else>
                          <v-col cols="9">
                            <v-text-field
                              v-model="formInputPaymentStatus.payment_status"
                              class="vuetify-custom"
                              solo
                              v-bind:style="{
                                color: '#1e2758 !important',
                                visibility: 'hidden',
                                position: 'absolute'
                              }"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;">
                          AJUKAN KE<span style="color: red;">*</span>
                        </span>
                      </v-col>
                      <v-col cols="9">
                        <v-select
                          v-model="formInput.cashbank_type"
                          id="cashbank_type_id"
                          :items="data_cashbank_type_combobox"
                          item-value="cashbank_type_id"
                          item-text="cashbank_type_name"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{ color: '#1e2758 !important' }"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  text
                  class="mb-4 btn btn-outline-secondary"
                  @click="close"
                >
                  BATALKAN
                </v-btn>
                <v-btn
                  color="primary"
                  light
                  class="mb-4"
                  @click="formSubmit"
                  style="text-transform: capitalize !important; color: white !important;"
                >
                  SIMPAN FORM BARU
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogSubmissionInfo" max-width="800px">
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-col>
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;"
                          >INFORMASI<span style="color: red;">*</span></span
                        >
                      </v-col>
                      <v-col cols="9">
                        <v-textarea
                          v-model="formInput.submission_info"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                        >
                        </v-textarea>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  text
                  class="mb-4 btn btn-outline-secondary"
                  @click="closeSubmissionInfo"
                >
                  BATALKAN
                </v-btn>
                <v-btn
                  color="primary"
                  light
                  class="mb-4"
                  @click="formSubmit"
                  style="text-transform: capitalize !important; color: white !important;"
                >
                  SIMPAN
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            label="CARI FORM PENGAJUAN"
            solo
            hide-details
            class="mb-12"
            style="width: 10px; box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);"
          ></v-text-field>
          <v-dialog v-model="dialogShowOfficialsTable" max-width="1000px">
            <v-card>
              <v-card-title>
                <span>DATA PEJABAT</span>
              </v-card-title>
              <v-card-text>
                <div class="form-group">
                  <v-toolbar flat class="mt-6">
                    <template>
                      <v-btn
                        color="primary"
                        text
                        class="mb-11"
                        @click="showHistoryOfficials(true)"
                        v-if="
                          user_role.toLowerCase() == 'atasan' ||
                            user_role.toLowerCase() == 'atasan operasional'
                        "
                      >
                        LIHAT RIWAYAT
                      </v-btn>
                    </template>
                    <v-dialog v-model="dialogCreateOfficials" max-width="800px">
                      <v-card>
                        <v-card-title>
                          <span class="text-h5">{{ formTitle }}</span>
                        </v-card-title>

                        <v-card-text>
                          <v-container>
                            <v-col>
                              <v-row>
                                <v-col cols="3" class="mt-4">
                                  <span style="color: black; font-size: 14px;"
                                    >JABATAN<span style="color: red;"
                                      >*</span
                                    ></span
                                  >
                                </v-col>
                                <v-col cols="9">
                                  <v-select
                                    v-model="formInput.position_id"
                                    id="position_id"
                                    :items="data_positions_combobox"
                                    @click="dataPositions_Combobox"
                                    item-value="position_id"
                                    item-text="position_name"
                                    class="vuetify-custom"
                                    solo
                                    v-bind:style="{
                                      color: '#c30e0e !important'
                                    }"
                                    readonly
                                  ></v-select>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="3" class="mt-4">
                                  <span style="color: black; font-size: 14px;"
                                    >PANGKAT<span style="color: red;">*</span></span
                                  >
                                </v-col>
                                <v-col cols="9">
                                  <v-text-field
                                    v-model="formInput.official_rank"
                                    class="vuetify-custom"
                                    solo
                                    v-bind:style="{
                                      color: '#1e2758 !important'
                                    }"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="3" class="mt-4">
                                  <span style="color: black; font-size: 14px;"
                                    >NAMA<span style="color: red;"
                                      >*</span
                                    ></span
                                  >
                                </v-col>
                                <v-col cols="9">
                                  <v-text-field
                                    v-model="formInput.official_name"
                                    class="vuetify-custom"
                                    solo
                                    v-bind:style="{
                                      color: '#c30e0e !important'
                                    }"
                                    readonly
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="3" class="mt-8">
                                  <span style="color: black; font-size: 14px;"
                                    >NOMINAL<span style="color: red;"
                                      >*</span
                                    ></span
                                  >
                                </v-col>
                                <v-col cols="9">
                                  <vuetify-money
                                    v-model="formInput.amount_approval"
                                    v-bind:placeholder="placeholder"
                                    v-bind:readonly="readonly"
                                    v-bind:disabled="disabled"
                                    v-bind:outlined="outlined"
                                    v-bind:clearable="clearable"
                                    v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
                                    v-bind:options="option"
                                    class="vuetify-custom"
                                    solo
                                    v-bind:style="{
                                      color: '#1e2758 !important'
                                    }"
                                  />
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="3" class="mt-4">
                                  <span style="color: black; font-size: 14px;">KETERANGAN</span>
                                </v-col>
                                <v-col cols="9">
                                  <v-textarea
                                    v-model="formInput.official_desc"
                                    class="vuetify-custom"
                                    solo
                                    v-bind:style="{
                                      color: '#1e2758 !important'
                                    }"
                                  >
                                  </v-textarea>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            class="mb-4 btn btn-outline-secondary"
                            @click="closeOfficials"
                          >
                            BATALKAN
                          </v-btn>
                          <v-btn
                            color="primary"
                            light
                            class="mb-4"
                            @click="formSubmit"
                            style="text-transform: capitalize !important; color: white !important;"
                          >
                            SIMPAN PERUBAHAN
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-spacer></v-spacer>
                    <v-text-field
                      v-model="searchOfficials"
                      prepend-inner-icon="mdi-magnify"
                      label="CARI PEJABAT"
                      solo
                      hide-details
                      class="mb-12"
                      style="width: 10px; box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);"
                    ></v-text-field>
                    <v-dialog v-model="dialogDeleteOfficials" max-width="500px">
                      <v-card>
                        <v-card-title class="text-h5"
                          >APAKAH YAKIN UNTUK MENGHAPUS DATA INI?</v-card-title
                        >
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn text @click="closeDeleteOfficials"
                            >BATAL</v-btn
                          >
                          <v-btn
                            text
                            @click="deleteDataOfficials"
                            v-bind:style="{
                              color: '#1e2758 !important'
                            }"
                            >OK</v-btn
                          >
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                  <v-data-table
                    v-model="selected"
                    @input="enterSelect($event)"
                    :headers="headers_officials"
                    :items="data_officials"
                    item-key="official_id"
                    show-select
                    :options.sync="options"
                    :search="searchOfficials"
                    class="elevation-1"
                    @toggle-select-all="selectAllToggle"
                    :loading="loadingTable"
                    loading-text="HARAP TUNGGU..."
                  >
                    <template
                      v-slot:[`item.data-table-select`]="{
                        item,
                        isSelected,
                        select
                      }"
                    >
                      <div v-if="item.paid_status == 0 || item.paid_status == 1">
                        <div v-if="item.paid_status != 0 && item.paid_status > 0">
                          <v-simple-checkbox
                            :value="true"
                            :readonly="item.paid_status"
                            :disabled="item.paid_status"
                            @input="select($event)"
                          ></v-simple-checkbox>
                        </div>
                        <div v-else>
                          <v-simple-checkbox
                            :value="isSelected"
                            :readonly="item.paid_status"
                            :disabled="item.paid_status"
                            @input="select($event)"
                          ></v-simple-checkbox>
                        </div>
                      </div>
                      <v-simple-checkbox
                        v-else-if="approvalStatus == 0"   
                        :value="isSelected"                     
                        @input="select($event)"
                      ></v-simple-checkbox>
                      <v-icon small color="black" v-else>
                        flaticon2-line
                      </v-icon>
                    </template>
                    <template v-slot:[`item.number`]="{ index }">
                      <td>
                        {{
                          options.page * options.itemsPerPage -
                            options.itemsPerPage +
                            (index + 1)
                        }}
                      </td>
                    </template>
                    <template v-slot:[`item.amount_submission`]="{ item }">
                      <td>RP {{ formatNumber(item.amount_submission) }}</td>
                    </template>
                    <template v-slot:[`item.amount_checking`]="{ item }">
                      <td
                        v-if="
                          user_role.toLowerCase() == 'atasan operasional' ||
                            user_role.toLowerCase() == 'atasan'
                        "
                      >
                        RP
                        {{ formatNumber(item.amount_checking) }}
                      </td>
                    </template>
                    <template v-slot:[`item.amount_approval`]="{ item }">
                      <td v-if="user_role.toLowerCase() == 'atasan'">
                        RP
                        {{ formatNumber(item.amount_approval) }}
                      </td>
                    </template>
                    <template v-slot:[`item.aksi`]="{ item }">
                      <div v-if="user_role.toLowerCase() == 'atasan'">
                        <v-btn
                          color="green"
                          light
                          small
                          class="mr-3"
                          @click="editItemOfficials(item)"
                          data-toggle="tooltip"
                          title="EDIT"
                          style="text-transform: capitalize !important; min-width: 0px; padding: 0 6px;"
                          v-if="item.status_approval == 0"
                        >
                          <v-icon small color="white">
                            mdi-pencil
                          </v-icon>
                        </v-btn>
                        <v-btn
                          color="red"
                          light
                          small
                          @click="deleteItemOfficials(item)"
                          data-toggle="tooltip"
                          title="HAPUS"
                          style="text-transform: capitalize !important; min-width: 0px; padding: 0 6px;"
                          v-if="item.status_approval == 0"
                        >
                          <v-icon small color="white">
                            mdi-delete
                          </v-icon>
                        </v-btn>
                        <v-icon
                          small
                          color="black"
                          v-if="item.status_approval == 1"
                          >flaticon2-line</v-icon
                        >
                      </div>
                      <div v-else-if="user_role.toLowerCase() == 'atasan operasional'">
                        <v-btn
                          color="green"
                          light
                          small
                          class="mr-3"
                          @click="editItemOfficials(item)"
                          data-toggle="tooltip"
                          title="EDIT"
                          style="text-transform: capitalize !important; min-width: 0px; padding: 0 6px;"
                          v-if="item.status_checking == 0"
                        >
                          <v-icon small color="white">
                            mdi-pencil
                          </v-icon>
                        </v-btn>
                        <v-btn
                          color="red"
                          light
                          small
                          @click="deleteItemOfficials(item)"
                          data-toggle="tooltip"
                          title="HAPUS"
                          style="text-transform: capitalize !important; min-width: 0px; padding: 0 6px;"
                          v-if="item.status_checking == 0"
                        >
                          <v-icon small color="white">
                            mdi-delete
                          </v-icon>
                        </v-btn>
                        <v-icon
                          small
                          color="black"
                          v-if="item.status_checking == 1"
                          >flaticon2-line</v-icon
                        >
                      </div>
                      <div
                        v-else-if="
                          user_role.toLowerCase() == 'atasan operasional' ||
                            user_role.toLowerCase() == 'operasional'
                        "
                      >
                        <v-btn
                          color="green"
                          light
                          small
                          class="mr-3"
                          @click="editItemOfficials(item)"
                          data-toggle="tooltip"
                          title="EDIT"
                          style="text-transform: capitalize !important; min-width: 0px; padding: 0 6px;"
                          v-if="item.status_submission == 0"
                        >
                          <v-icon small color="white">
                            mdi-pencil
                          </v-icon>
                        </v-btn>
                        <v-btn
                          color="red"
                          light
                          small
                          @click="deleteItemOfficials(item)"
                          data-toggle="tooltip"
                          title="HAPUS"
                          style="text-transform: capitalize !important; min-width: 0px; padding: 0 6px;"
                          v-if="item.status_submission == 0"
                        >
                          <v-icon small color="white">
                            mdi-delete
                          </v-icon>
                        </v-btn>
                        <v-icon
                          small
                          color="black"
                          v-if="item.status_submission == 1"
                        >
                          flaticon2-line
                        </v-icon>
                        <!-- <v-icon small color="black">flaticon2-line</v-icon> -->
                      </div>
                    </template>
                    <template
                      v-slot:footer
                      v-if="user_role.toLowerCase() == 'operasional' || user_role.toLowerCase() == 'atasan operasional' || user_role.toLowerCase() == 'atasan'"
                    >
                      <v-btn
                        v-if="user_role.toLowerCase() == 'operasional' && checkPaidStatusValue == 0 && approvalStatus == 1"
                        color="blue"
                        light
                        small
                        class="ml-2 my-4"
                        @click="updateDataOfficialsPaidStatus()"
                        data-toggle="tooltip"
                        title="BAYARKAN"
                        style="text-transform: capitalize !important; min-width: 0px; padding: 0 6px;"
                      >
                        <span small style="color: white;">
                          BAYARKAN
                        </span>
                      </v-btn>
                      <v-btn
                        v-if="(user_role.toLowerCase() == 'operasional' && approvalStatus == 0 && checkingStatus == 0) || (user_role.toLowerCase() != 'operasional' && approvalStatus == 0)"
                        color="red"
                        light
                        small
                        class="ml-2 my-4"
                        @click="bulkDeleteDataOfficials()"
                        data-toggle="tooltip"
                        title="HAPUS"
                        style="text-transform: capitalize !important; min-width: 0px; padding: 0 6px;"
                      >
                        <span small style="color: white;">
                          HAPUS DARI DAFTAR
                        </span>
                      </v-btn>
                    </template>
                  </v-data-table>
                </div>
              </v-card-text>
              <div v-if="seenHistory">
                <v-card-title>
                  <span>DATA RIWAYAT PEJABAT</span>
                </v-card-title>
                <v-card-text>
                  <div class="form-group">
                    <v-toolbar flat class="mt-6">
                      <v-col cols="2">
                        <vuetify-money
                          v-model="search_history.amount_range_from"
                          v-bind:placeholder="placeholder"
                          v-bind:readonly="readonly"
                          v-bind:disabled="disabled"
                          v-bind:outlined="outlined"
                          v-bind:clearable="clearable"
                          v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
                          v-bind:options="option"
                          @change="
                            onChangeSearchHistoryAmountFrom(
                              search_history.amount_range_from
                            )
                          "
                          class="vuetify-custom mb-6"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                        />
                      </v-col>
                      <span class="mb-11">-</span>
                      <v-col cols="2">
                        <vuetify-money
                          v-model="search_history.amount_range_to"
                          v-bind:placeholder="placeholder"
                          v-bind:readonly="readonly"
                          v-bind:disabled="disabled"
                          v-bind:outlined="outlined"
                          v-bind:clearable="clearable"
                          v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
                          v-bind:options="option"
                          @change="
                            onChangeSearchHistoryAmountTo(
                              search_history.amount_range_to
                            )
                          "
                          class="vuetify-custom mb-6"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                        />
                      </v-col>
                      <v-btn
                        color="primary"
                        class="mb-12"
                        text
                        @click="dataHistoryOfficials()"
                      >
                        CARI
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-text-field
                        v-model="searchHistoryOfficials"
                        prepend-inner-icon="mdi-magnify"
                        label="CARI RIWAYAT PEJABAT"
                        solo
                        hide-details
                        class="mb-12 mr-4"
                        style="width: 10px; box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);"
                      ></v-text-field>
                      <v-btn
                        color="primary"
                        dark
                        class="mb-12"
                        @click="generateReportAll()"
                        v-if="seenButtonPrint"
                      >
                        CETAK
                      </v-btn>
                    </v-toolbar>
                    <v-data-table
                      :headers="headers_history_officials"
                      :items="data_history_officials"
                      :item-key="official_id"
                      :options.sync="options"
                      :search="searchHistoryOfficials"
                      sort-by="Id"
                      class="elevation-1"
                      :loading="loadingTable"
                      loading-text="HARAP TUNGGU..."
                    >
                      <template v-slot:[`item.number`]="{ index }">
                        <td>
                          {{
                            options.page * options.itemsPerPage -
                              options.itemsPerPage +
                              (index + 1)
                          }}
                        </td>
                      </template>
                      <template v-slot:[`item.project_amount`]="{ item }">
                        <td>Rp {{ formatNumber(item.project_amount) }}</td>
                      </template>
                      <template v-slot:[`item.amount`]="{ item }">
                        <td>Rp {{ formatNumber(item.amount) }}</td>
                      </template>
                    </v-data-table>
                  </div>
                </v-card-text>
              </div>
              <v-card-actions>
                <div
                  v-if="
                    user_role.toLowerCase() == 'operasional'
                  "
                >
                  <v-btn
                    color="primary"
                    text
                    @click="updateDataSubmissionFormCashSubmissionStatus()"
                    v-if="cashSubmissionStatus == 0"
                  >
                    PENGAJUAN SELESAI
                  </v-btn>
                  <v-btn
                    color="primary"
                    text
                    @click="showHistoryOfficials(false)"
                  >
                    TUTUP
                  </v-btn>
                </div>
                <div v-else-if="user_role.toLowerCase() == 'atasan operasional'">
                  <v-btn
                    color="primary"
                    text
                    @click="updateDataSubmissionFormChecking()"
                    v-if="cashSubmissionStatus == 1 && checkingStatus == 0"
                  >
                    CEK SELESAI
                  </v-btn>
                  <v-btn
                    color="primary"
                    text
                    @click="showHistoryOfficials(false)"
                  >
                    TUTUP
                  </v-btn>
                </div>
                <div v-else-if="user_role.toLowerCase() == 'atasan'">
                  <v-btn
                    color="primary"
                    text
                    @click="updateDataSubmissionFormApproval()"
                    v-if="checkingStatus == 1 && approvalStatus == 0"
                  >
                    APPROV
                  </v-btn>
                  <v-btn
                    color="primary"
                    text
                    @click="showHistoryOfficials(false)"
                  >
                    TUTUP
                  </v-btn>
                </div>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="dialogShowHistoryOfficialsTable"
            max-width="1000px"
          >
            <v-card>
              <v-card-title>
                <span>DATA RIWAYAT PEJABAT</span>
              </v-card-title>
              <v-card-text>
                <div class="form-group">
                  <v-toolbar flat class="mt-6">
                    <v-col cols="2">
                      <vuetify-money
                        v-model="search_history.amount_range_from"
                        v-bind:placeholder="placeholder"
                        v-bind:readonly="readonly"
                        v-bind:disabled="disabled"
                        v-bind:outlined="outlined"
                        v-bind:clearable="clearable"
                        v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
                        v-bind:options="option"
                        @change="
                          onChangeSearchHistoryAmountFrom(
                            search_history.amount_range_from
                          )
                        "
                        class="vuetify-custom mb-6"
                        solo
                        v-bind:style="{
                          color: '#1e2758 !important'
                        }"
                      />
                    </v-col>
                    <span class="mb-11">-</span>
                    <v-col cols="2">
                      <vuetify-money
                        v-model="search_history.amount_range_to"
                        v-bind:placeholder="placeholder"
                        v-bind:readonly="readonly"
                        v-bind:disabled="disabled"
                        v-bind:outlined="outlined"
                        v-bind:clearable="clearable"
                        v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
                        v-bind:options="option"
                        @change="
                          onChangeSearchHistoryAmountTo(
                            search_history.amount_range_to
                          )
                        "
                        class="vuetify-custom mb-6"
                        solo
                        v-bind:style="{
                          color: '#1e2758 !important'
                        }"
                      />
                    </v-col>
                    <v-btn
                      color="primary"
                      class="mb-12"
                      text
                      @click="dataHistoryOfficials()"
                    >
                      CARI
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-text-field
                      v-model="searchHistoryOfficials"
                      prepend-inner-icon="mdi-magnify"
                      label="CARI RIWAYAT PEJABAT"
                      solo
                      hide-details
                      class="mb-12"
                      style="width: 10px; box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);"
                    ></v-text-field>
                  </v-toolbar>
                  <v-data-table
                    :headers="headers_history_officials"
                    :items="data_history_officials"
                    :item-key="official_id"
                    :options.sync="options"
                    :search="searchHistoryOfficials"
                    sort-by="Id"
                    class="elevation-1"
                    :loading="loadingTable"
                    loading-text="HARAP TUNGGU..."
                  >
                    <template v-slot:[`item.number`]="{ index }">
                      <td>
                        {{
                          options.page * options.itemsPerPage -
                            options.itemsPerPage +
                            (index + 1)
                        }}
                      </td>
                    </template>
                    <template v-slot:[`item.project_amount`]="{ item }">
                      <td>Rp {{ formatNumber(item.project_amount) }}</td>
                    </template>
                    <template v-slot:[`item.amount`]="{ item }">
                      <td>Rp {{ formatNumber(item.amount) }}</td>
                    </template>
                  </v-data-table>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  color="primary"
                  text
                  @click="dialogShowHistoryOfficialsTable = false"
                >
                  TUTUP
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogMoneyStatus" max-width="1000px">
            <v-card>
              <v-card-title>
                <span class="text-h5">STATUS UANG</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-col>
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;">
                          STATUS UANG<span style="color: red;">*</span>
                        </span>
                      </v-col>
                      <v-col cols="9">
                        <v-select
                          v-model="add_submission_form.money_status"
                          id="money_status_id"
                          :items="data_money_status_combobox"
                          item-value="money_status_id"
                          item-text="money_status_name"
                          class="vuetify-custom"
                          solo
                          v-bing:style="{ color: '#1e2758 !important' }"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  text
                  class="mb-4 btn btn-outline-secondary"
                  @click="closeMoneyStatus"
                >
                  BATALKAN
                </v-btn>
                <v-btn
                  color="primary"
                  light
                  class="mb-4"
                  @click="updateDataSubmissionFormMoneyStatus"
                  style="text-transform: capitalize !important; color: white !important;"
                >
                  SIMPAN
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >APAKAH YAKIN UNTUK MENGHAPUS DATA INI?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="closeDelete">BATAL</v-btn>
                <v-btn
                  text
                  @click="deleteDataSubmissionForm"
                  v-bind:style="{
                    color: '#1e2758 !important'
                  }"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-data-table
          :headers="headers"
          :items="data_submission_form"
          :item-key="submission_number"
          :options.sync="options"
          :search="search"
          sort-by="Id"
          class="elevation-1 mb-12"
          :item-class="row_bg_color"
          :loading="loadingTable"
          loading-text="HARAP TUNGGU..."
        >
          <template v-slot:[`item.number`]="{ index }">
            <td>
              {{
                options.page * options.itemsPerPage -
                  options.itemsPerPage +
                  (index + 1)
              }}
            </td>
          </template>
          <template v-slot:[`item.submission_number`]="{ item }">
            <a 
              target="_blank" 
              :href="'pdf.php?mode=ADM&workgroup='+item.workgroup_id+'&proyek='+item.project_name+
              '&nk='+item.project_amount+'&id='+item.submission_number+'&tanggal='+item.date_submission+
              '&keperluan='+item.financing_name+'&jumlah='+item.amount+'&keterangan='+item.special_cashbank_desc+
              '&dibuat='+item.created_by+'&diperiksa='+item.posted_by+'&disetujui='+item.approval_by">
              <td>{{item.submission_number}}</td>
            </a>
          </template>
          <template v-slot:[`item.amount`]="{ item }">
            <td>Rp {{ formatNumber(item.amount) }}</td>
          </template>
          <template v-slot:[`item.payment_status`]="{ item }">
            <td>{{ item.payment_status }}</td>
          </template>
          <template v-slot:[`item.money_status`]="{ item }">
            <td v-if="item.money_status == 'S'">
              Pengajuan
            </td>
            <td v-else-if="item.money_status == 'HO'">
              Uang Diserahkan
            </td>
            <td v-else-if="item.money_status == 'R'">
              Uang Dikembalikan
            </td>
            <td v-else-if="item.money_status == ' '">
              -
            </td>
          </template>
          <template v-slot:[`item.created_by`]="{ item }">
            <td>
              {{ item.created_by == "" ? "-" : item.created_by }}
            </td>
          </template>
          <template v-slot:[`item.date_created`]="{ item }">
            <td>
              {{ item.date_created == null ? "-" : item.date_created }}
            </td>
          </template>
          <template v-slot:[`item.checking_by`]="{ item }">
            <td>
              {{ item.checking_by == "" ? "-" : item.checking_by }}
            </td>
          </template>
          <template v-slot:[`item.date_checking`]="{ item }">
            <td>
              {{ item.date_checking == null ? "-" : item.date_checking }}
            </td>
          </template>
          <template v-slot:[`item.approval_by`]="{ item }">
            <td>
              {{ item.approval_by == "" ? "-" : item.approval_by }}
            </td>
          </template>
          <template v-slot:[`item.date_approval`]="{ item }">
            <td>
              {{ item.date_approval == null ? "-" : item.date_approval }}
            </td>
          </template>
          <template v-slot:[`item.submission_info`]="{ item }">
            <tr
              @mouseover="editedIndex = data_submission_form.index"
              @mouseleave="editedIndex = -4"
            >
              <td>
                <div class="d-flex align-items-center position-relative">
                  <v-btn
                    color="blue"
                    light
                    small
                    class="mx-auto position-absolute"
                    @click="editItemSubmissionInfo(item)"
                    data-toggle="tooltip"
                    title="EDIT INFO"
                    v-show="data_submission_form.index == editedIndex"
                    v-if="user_role.toLowerCase() === 'operasional' && item.checking_status === 0"
                  >
                    <span small style="color: white;">EDIT</span>
                  </v-btn>
                  <p class="my-2 mx-auto">{{ item.submission_info }}</p>
                </div>
              </td>
            </tr>
          </template>
          <template v-slot:[`item.aksi`]="{ item }">
            <div v-if="user_role.toLowerCase() === 'atasan'">
              <v-btn
                color="blue"
                light
                small
                class="mr-3 my-2"
                @click="officialsSelect(item)"
                data-toggle="tooltip"
                title="LIHAT PEJABAT"
                style="text-transform: capitalize !important; min-width: 0px; padding: 0 6px;"
              >
                <v-span small style="color: white;">
                  LIHAT PEJABAT
                </v-span>
              </v-btn>
            </div>
            <div v-else-if="user_role.toLowerCase() === 'atasan operasional'">
              <v-btn
                color="blue"
                light
                small
                class="mr-3 my-2"
                @click="officialsSelect(item)"
                data-toggle="tooltip"
                title="LIHAT PEJABAT"
                style="text-transform: capitalize !important; min-width: 0px; padding: 0 6px;"
              >
                <v-span small style="color: white;">
                  LIHAT PEJABAT
                </v-span>
              </v-btn>
            </div>
            <div v-else>
              <v-btn
                color="blue"
                light
                small
                class="mr-3"
                @click="officialsSelect(item)"
                data-toggle="tooltip"
                title="LIHAT PEJABAT"
                style="text-transform: capitalize !important; min-width: 0px; padding: 0 6px;"
              >
                <v-span small style="color: white;">
                  LIHAT PEJABAT
                </v-span>
              </v-btn>
              <v-btn
                color="green"
                light
                small
                class="mr-3"
                @click="dataNotification(item)"
                data-toggle="tooltip"
                title="NOTIFIKASI"
                style="text-transform: capitalize !important; min-width: 0px; padding: 0 6px;"
                v-if="item.money_status == 'C'"
              >
                <v-span small style="color: white;">
                  NOTIFIKASI
                </v-span>
              </v-btn>
              <v-btn
                color="green"
                light
                small
                class="mr-3"
                @click="editItem(item)"
                data-toggle="tooltip"
                title="EDIT"
                style="text-transform: capitalize !important; min-width: 0px; padding: 0 6px;"
                v-if="item.checking_status === 0"
              >
                <v-icon small color="white">
                  mdi-pencil
                </v-icon>
              </v-btn>
              <v-btn
                color="red"
                light
                small
                @click="deleteItem(item)"
                data-toggle="tooltip"
                title="HAPUS"
                style="text-transform: capitalize !important; min-width: 0px; padding: 0 6px;"
                v-if="item.checking_status === 0"
              >
                <v-icon small color="white">
                  mdi-delete
                </v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </template>
      <!-- PDF ALL Session -->
      <template>
        <div>
          <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :preview-modal="true"
            :paginate-elements-by-height="1400"
            :pdf-quality="2"
            :manual-pagination="false"
            :html-to-pdf-options="htmlToPdfAllOptions"
            pdf-content-width="100%"
            @hasStartedGeneration="hasStartedGeneration()"
            @hasGenerated="hasGenerated($event)"
            ref="html2PdfAll"
          >
            <section slot="pdf-content">
              <!-- PDF Content Here -->
              <section class="pdf-item">
                <div align="left">
                  <p
                    style="
                          font-family: 'Verdana';
                          font-style: normal;
                          font-size: 16px;
                        "
                  >
                    RIWAYAT PEJABAT
                  </p>
                </div>
                <br />
                <div align="left">
                  <table style="border-collapse: collapse; border-spacing: 0;">
                    <tr>
                      <td
                        style="font-family: 'Verdana'; font-style: normal; font-size: 12px; font-weight: 600; line-height: 14px; padding-right: 10px"
                      >
                        PROYEK:
                      </td>
                      <td
                        style="font-family: 'Verdana'; font-style: normal; font-size: 12px; font-weight: 600; line-height: 14px; padding-right: 10px"
                      >
                        {{ data_history_officials_print.project_name }}
                      </td>
                    </tr>
                  </table>

                  <table style="border-collapse: collapse; border-spacing: 0;">
                    <tr>
                      <td
                        style="font-family: 'Verdana'; font-style: normal; font-size: 12px; font-weight: 600; line-height: 14px; padding-right: 10px"
                      >
                        NOMINAL PROYEK:
                      </td>
                      <td
                        style="font-family: 'Verdana'; font-style: normal; font-size: 12px; font-weight: 600; line-height: 14px; padding-right: 10px"
                      >
                        RP {{ formatNumber(data_history_officials_print.project_amount) }}
                      </td>
                    </tr>
                  </table>
                </div>
                <br />
                <div align="center">
                  <table border="1" width="100%">
                    <thead>
                      <tr align="center">
                        <th
                          scope="col"
                          style="font-family: 'Verdana'; font-style: normal; font-size: 12px;"
                          width="5%"
                        >
                          NO
                        </th>
                        <th
                          scope="col"
                          style="font-family: 'Verdana'; font-style: normal; font-size: 12px;"
                        >
                          NAMA
                        </th>
                        <th
                          scope="col"
                          style="font-family: 'Verdana'; font-style: normal; font-size: 12px;"
                        >
                          JABATAN
                        </th>
                        <th
                          scope="col"
                          style="font-family: 'Verdana'; font-style: normal; font-size: 12px;"
                        >
                          NOMINAL
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(data, index) in data_history_officials"
                        :key="data.official_id"
                        align="center"
                      >
                        <td
                          style="font-family: 'Verdana'; font-style: normal; font-size: 12px;"
                        >
                          {{ index + 1 }}
                        </td>
                        <td
                          style="font-family: 'Verdana'; font-style: normal; font-size: 12px;"
                        >
                          {{ data.official_name }}
                        </td>
                        <td
                          style="font-family: 'Verdana'; font-style: normal; font-size: 12px;"
                        >
                          {{ data.position_name }}
                        </td>
                        <td
                          style="font-family: 'Verdana'; font-style: normal; font-size: 12px;"
                        >
                          {{ data.official_rank }}
                        </td>
                        <td
                          style="font-family: 'Verdana'; font-style: normal; font-size: 12px;"
                        >
                          RP {{ formatNumber(data.amount) }}
                        </td>
                        <td
                          style="font-family: 'Verdana'; font-style: normal; font-size: 12px;"
                        >
                          {{ data.official_desc }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </section>
              <div class="html2pdf__page-break" />
            </section>
          </vue-html2pdf>
        </div>
      </template>
      <!-- END PDF ALL Session -->
    </div>
    <!-- End Body -->
  </div>
</template>

<style lang="scss">
.topbar {
  .dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<style scoped>
.vuetify-custom >>> .v-text-field__slot label {
  color: #1e2758 !important;
}

.vuetify-custom >>> .v-select__slot label {
  color: #1e2758 !important;
}

.vuetify-custom >>> .v-input__icon i {
  color: #1e2758 !important;
}

.position-relative:hover .position-absolute {
  top: auto;
}
</style>

<style>
.bg-green {
  background-color: rgba(0, 255, 0, 0.2);
}

.bg-red {
  background-color: rgba(255, 0, 0, 0.2);
}

.bg-yellow {
  background-color: rgba(255, 255, 0, 0.2);
}

.bg-blue {
  background-color: rgba(2, 118, 255, 0.2);
}

.bg-orange {
  background-color: rgba(255, 165, 0, 0.2);
}
</style>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTLayoutHeader from "@/assets/js/layout/base/header.js";
import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";
import { LOGOUT } from "@/core/services/store/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import ApprovalNotification from "@/view/pages/dashboard/ApprovalNotificationBell.vue";
import localStorage from "@/core/services/store/localStorage";
import Services from "@/core/services/app_internal/Services";
import ApiService from "@/core/services/api.service";
import VueHtml2pdf from "vue-html2pdf";
import Swal from "sweetalert2";
import "@/core/plugins/vuetify-money.js";
import { formatNumber } from "@/helpers/helper.js";
import { formatDate } from "@/helpers/helper.js";
import axios from 'axios';

export default {
  name: "submission-form",

  components: {
    ApprovalNotification,
    VueHtml2pdf
  },

  data: () => ({
    search: "",
    selected: [],
    disabledCount: 0,
    searchOfficials: "",
    searchHistoryOfficials: "",
    dialog: false,
    dialogSubmissionInfo: false,
    dialogCreateOfficials: false,
    dialogDelete: false,
    dialogDeleteOfficials: false,
    dialogShowOfficialsTable: false,
    dialogShowHistoryOfficialsTable: false,
    dialogMoneyStatus: false,
    create_data_submission_form: [],
    update_data_submission_form: [],
    update_data_submission_form_amount: [],
    delete_data_submission_form: [],
    create_data_officials: [],
    create_data_voucher: [],
    update_data_officials: [],
    delete_data_officials: [],
    data_submission_form: [],
    data_escrow_notification: [],
    data_bank_guarantee_notification: [],
    data_submission_form_finance_notification: [],
    data_officials: [],
    data_history_officials: [],
    data_workgroup_combobox: [],
    data_organizational_units_combobox: [],
    data_project_combobox: [],
    data_activities_combobox: [],
    data_work_units_combobox: [],
    data_sub_work_units_combobox: [],
    data_part_of_sub_work_units_combobox: [],
    data_positions_combobox: [],
    data_sub_organizational_unit_combobox: [],
    data_payment_status_combobox: [
      {
        payment_status_id: "100%",
        payment_status_name: "100%"
      },
      {
        payment_status_id: "80%",
        payment_status_name: "80%"
      },
      {
        payment_status_id: "20%",
        payment_status_name: "20%"
      },
      {
        payment_status_id: "Lain-lain",
        payment_status_name: "LAIN-LAIN"
      }
    ],
    data_cashbank_type_combobox: [
      {
        cashbank_type_id: "KBK",
        cashbank_type_name: "KAS BESAR"
      },
      {
        cashbank_type_id: "KKK",
        cashbank_type_name: "KAS KECIL"
      }
    ],
    data_money_status_combobox: [
      {
        money_status_id: "S",
        money_status_name: "PENGAJUAN"
      },
      {
        money_status_id: "HO",
        money_status_name: "UANG DISERAHKAN"
      },
      {
        money_status_id: "R",
        money_status_name: "UANG DIKEMBALIKAN"
      }
    ],
    data_submission_form_date: {
      date_submission: new Date().toISOString().substr(0, 10),
      date: new Date().toISOString().substr(0, 10)
    },
    data_history_officials_print: {
      project_id: "",
      project_name: "",
      project_amount: ""
    },
    add_submission_form: {
      workgroup_id: "",
      organizational_unit_id: "",
      work_unit_id: "",
      sub_work_unit_id: "",
      sub_work_unit_name: "",
      part_of_sub_work_unit_id: "",
      part_of_sub_work_unit_name: "",
      project_id: "",
      activity_id: "",
      date_submission: new Date().toISOString().substr(0, 10),
      submission_desc: "",
      submission_type: "",
      submission_permission: "",
      transaction_type: "",
      amount: 0,
      payment_status: "",
      money_status: " ",
      sub_organizational_unit: "",
      submission_info: "",
      cashbank_type: "",
      cashbank_permission: ""
    },
    add_submission_form_payment_status: {
      payment_status: ""
    },
    add_officials: {
      official_id: "",
      submission_number: "",
      organizational_unit_id: "",
      work_unit_id: "",
      sub_work_unit_id: "",
      sub_work_unit_name: "",
      part_of_sub_work_unit_id: "",
      part_of_sub_work_unit_name: "",
      position_id: "",
      position_name: "",
      official_rank: "",
      official_name: "",
      official_desc: "",
      official_rank: "",
      amount_submission: 0,
      amount_checking: 0,
      amount_approval: 0
    },
    add_submission_form_not_null: {
      date_submission_form: false,
      date_submission: formatDate()
    },
    search_history: {
      amount_range_from: 0,
      amount_range_to: 0
    },
    submitted: false,
    headers: [],
    headers_officials: [],
    headers_history_officials: [],
    editedIndex: -1,
    deletedIndex: -1,
    editedItem: {},
    editedItemOfficials: {},
    editedItemPaymentStatus: {},
    editedSubmissionInfo: {},
    deletedItem: {},
    deletedItemOfficials: {},
    defaultItem: {},
    isFirstLoad: true,
    options: {
      page: 1,
      itemsPerPage: 5,
      sortBy: [],
      sortDesc: [],
      groupBy: [],
      groupDesc: [],
      multiSort: true,
      mustSort: false
    },
    htmlToPdfAllOptions: {
      margin: 0.1,
      filename: `RIWAYAT PEJABAT ADMINISTRASI.pdf`,
      jsPDF: {
        unit: "in",
        format: "a4",
        orientation: "portrait"
      }
    },
    label: "",
    placeholder: "",
    readonly: false,
    disabled: false,
    outlined: false,
    clearable: false,
    valueWhenIsEmpty: "",
    option: {
      locale: "en-US",
      prefix: "",
      suffix: "",
      length: 20,
      precision: 0
    },
    date_submission: "",
    date: "",
    user_role: "",
    seen: false,
    seenHistory: false,
    seenButtonPrint: false,
    submissionNumber: "",
    organizationalUnitId: "",
    workUnitId: "",
    submissionAmount: "",
    moneyStatus: 0,
    cashSubmissionStatus: 0,
    checkingStatus: "",
    approvalStatus: "",
    dateSubmission: "",
    workgroupId: "",
    submissionType: "",
    cashbankType: "",
    cashbankPermission: "",
    projectId: "",
    submissionDesc: "",
    submissionAmountToVoucher: "",
    createdBy: "",
    user_id: "",
    user_fullname: "",
    email: "",
    count_escrow_notification: 0,
    count_bank_guarantee_notification: 0,
    count_submission_form_finance_notification: 0,
    paymentStatus: "",
    loadingTable: true
  }),

  computed: {
    ...mapGetters([
      "layoutConfig",
      "getClasses",
      "currentUserPersonalInfo",
      "currentUserPhoto"
    ]),

    headerMenuEnabled() {
      return !!this.layoutConfig("header.menu.self.display");
    },

    headerClasses() {
      const classes = this.getClasses("header");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    headerMenuClasses() {
      const classes = this.getClasses("header_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    totalNotification: function() {
      let countEscrowNotification = this.count_escrow_notification;
      let countBankGuaranteeNotification = this
        .count_bank_guarantee_notification;
      let countSubmissionFormFinanceNotification = this
        .count_submission_form_finance_notification;
      let totalNotification = 0;

      totalNotification += parseInt(
        countEscrowNotification +
          countBankGuaranteeNotification +
          countSubmissionFormFinanceNotification
      );

      return totalNotification;
    },

    config() {
      return this.layoutConfig();
    },

    formTitle() {
      let result = "";

      if (this.editedIndex === -1) {
        result = "BUAT FORM PENGAJUAN";
      } else if (this.editedIndex === -2) {
        result = "BUAT PEJABAT";
      } else if (this.editedIndex === -3) {
        result = "UBAH PEJABAT";
      } else if (this.editedIndex === -4) {
        result = "BERIKAN INFORMASI";
      } else {
        result = "UBAH FORM PENGAJUAN";
      }

      return result;
    },

    formInput() {
      let result = "";

      if (this.editedIndex === -1) {
        result = this.add_submission_form;
      } else if (this.editedIndex === -2) {
        result = this.add_officials;
      } else if (this.editedIndex === -3) {
        result = this.editedItemOfficials;
      } else if (this.editedIndex === -4) {
        result = this.editedSubmissionInfo;
      } else {
        result = this.editedItem;
      }

      return result;
    },

    formInputPaymentStatus() {
      let result = "";

      if (this.editedIndex === -1) {
        result = this.add_submission_form_payment_status;
      } else {
        result = this.editedItemPaymentStatus;
      }

      return result;
    },

    formInputNotClear() {
      let result = "";

      if (this.editedIndex === -1) {
        result = this.add_submission_form_not_null;
      } else {
        result = this.editedItem;
      }

      return result;
    },

    formSubmit() {
      let result = "";

      if (this.editedIndex === -1) {
        result = this.createDataSubmissionForm;
      } else if (this.editedIndex === -2) {
        result = this.createDataOfficials;
      } else if (this.editedIndex === -3) {
        result = this.updateDataOfficials;
      } else if (this.editedIndex === -4) {
        result = this.updateDataSubmissionFormInfo;
      } else {
        result = this.updateDataSubmissionForm;
      }

      return result;
    },

    computedDate() {
      return this.formatDate(this.date_submission);
    },

    computedDateModified() {
      return this.formatDate(this.date);
    },

    selectAllSubUnitParts() {
      return (
        this.formInput.part_of_sub_work_unit_id.length ===
        this.data_part_of_sub_work_units_combobox.length
      );
    },

    selectSomePartsOfTheSubUnit() {
      return (
        this.formInput.part_of_sub_work_unit_id.length > 0 &&
        !this.selectAllSubUnitParts
      );
    },

    icon() {
      if (this.selectAllSubUnitParts) return "mdi-close-box";
      if (this.selectSomePartsOfTheSubUnit) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },

    dialogCreateOfficials(val) {
      val || this.closeOfficials();
    },

    dialogSubmissionInfo(val) {
      val || this.closeSubmissionInfo();
    },

    dialogDelete(val) {
      val || this.closeDelete();
    },

    dialogDeleteOfficials(val) {
      val || this.closeDeleteOfficials();
    },

    dialogMoneyStatus(val) {
      val || this.closeMoneyStatus();
    },

    options: {
      async handler(val) {
        val;
        if (!this.isFirstLoad) await this.dataDoMasuk();
        this.loading = false;
      },
      deep: true
    },

    date_submission() {
      this.formInputNotClear.date_submission = this.formatDate(
        this.date_submission
      );
    },

    date() {
      this.formInputNotClear.date = this.formatDate(this.date);
    }
  },

  created() {
    const self = this;
    this.data_officials.map(item => {
      if (item.paid_status === 0 ? false : true) self.disabledCount += 1;
    });

    this.initHeders();
    this.initHedersOfficials();
    this.initHedersHistoryOfficials();
    this.initSubOrganizationalUnitCombobox();
  },

  mounted() {
    // Init Desktop & Mobile Headers
    KTLayoutHeader.init("kt_header", "kt_header_mobile");

    // Init Header Menu
    KTLayoutHeaderMenu.init(
      this.$refs["kt_header_menu"],
      this.$refs["kt_header_menu_wrapper"]
    );

    const headerRef = this.$refs["kt_header"];

    headerRef.querySelectorAll("a[class='menu-link']").forEach(item => {
      item.addEventListener("click", () => {
        KTLayoutHeaderMenu.getOffcanvas().hide();
      });
    });

    this.load();

    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);

    this.user_id = localStorage.getLocalStorage("UID");
    this.user_fullname = localStorage.getLocalStorage("User_Fullname");
    this.email = localStorage.getLocalStorage("User_Email");
    this.user_role = localStorage.getLocalStorage("User_Role");

    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Form Pengajuan" }]);
  },

  methods: {
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },

    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    },

    row_bg_color(item) {
      if (item.money_status == "HO") {
        return "bg-green";
      } else if (item.money_status == "R") {
        return "bg-red";
      } else if (item.money_status == "S") {
        return "bg-yellow";
      } else if (item.money_status == "C") {
        return "bg-blue";
      } else if (item.money_status == "A") {
        return "bg-orange";
      }
    },

    selectAllToggle(props) {
      if (
        this.selected.length !=
        this.data_officials.length - this.disabledCount
      ) {
        this.selected = [];
        const self = this;
        props.items.forEach(item => {
          if (!(item.paid_status === 0) ? false : true) {
            self.selected.push(item);
          }
        });
      } else this.selected = [];
    },

    enterSelect(values) {
      let data_officials = values.map(function(value) {
        return value.official_id;
      });

      this.add_officials.official_id = data_officials;
    },

    toggle() {
      this.$nextTick(() => {
        if (this.selectAllSubUnitParts) {
          this.formInput.part_of_sub_work_unit_id = [];
        } else {
          this.formInput.part_of_sub_work_unit_id = this.data_part_of_sub_work_units_combobox.slice();
        }
      });
    },

    getHeaders() {
      return [
        {
          text: "Id",
          sortable: false,
          value: "submission_number",
          align: " d-none"
        },
        { text: "NO", value: "number", width: "80px" },
        {
          text: "NO PENGAJUAN",
          sortable: true,
          value: "submission_number",
          width: "150px"
        },
        {
          text: "PERUSAHAAN",
          sortable: true,
          value: "workgroup_name",
          width: "140px"
        },
        {
          text: "TGL TRANSFER",
          sortable: true,
          value: "date_submission",
          width: "150px"
        },
        {
          text: "UNIT MATRA",
          sortable: true,
          value: "organizational_unit_name",
          width: "160px"
        },
        {
          text: "SUB UNIT MATRA",
          sortable: true,
          value: "sub_organizational_unit",
          width: "190px"
        },
        {
          text: "SATUAN KERJA",
          sortable: true,
          value: "work_unit_name",
          width: "220px"
        },
        {
          text: "SUB SATUAN KERJA",
          sortable: true,
          value: "sub_work_unit_name",
          width: "170px"
        },
        {
          text: "PROYEK",
          sortable: true,
          value: "project_name",
          width: "130px"
        },
        {
          text: "KEGIATAN",
          sortable: true,
          value: "activity_name",
          width: "140px"
        },
        {
          text: "DESKRIPSI",
          sortable: true,
          value: "submission_desc",
          width: "130px"
        },
        {
          text: "NOMINAL",
          sortable: true,
          value: "amount",
          width: "130px"
        },
        {
          text: "STATUS PEMBAYARAN",
          sortable: true,
          value: "payment_status",
          width: "190px"
        },
        {
          text: "STATUS UANG",
          sortable: true,
          value: "money_status",
          width: "140px"
        },
        {
          text: "DIAJUKAN OLEH",
          sortable: true,
          value: "created_by",
          width: "150px"
        },
        {
          text: "TGL DIAJUKAN",
          sortable: true,
          value: "date_created",
          width: "170px"
        },
        {
          text: "DICEK OLEH",
          sortable: true,
          value: "checking_by",
          width: "130px"
        },
        {
          text: "TGL DICEK",
          sortable: true,
          value: "date_checking",
          width: "150px"
        },
        {
          text: "DISETUJUI OLEH",
          sortable: true,
          value: "approval_by",
          width: "150px"
        },
        {
          text: "TGL DISETUJUI",
          sortable: true,
          value: "date_approval",
          width: "170px"
        },
        {
          text: "INFO",
          sortable: true,
          value: "submission_info",
          width: "100px",
          align: localStorage.getLocalStorage("User_Role").toLowerCase() != "atasan" ? "" : " d-none"
        },
        {
          text: "EDIT",
          value: "aksi",
          sortable: false,
          align: "center",
          width: "280px"
        }
      ];
    },

    getHeadersOfficials() {
      return [
        {
          text: "Id",
          sortable: false,
          value: "official_id",
          align: " d-none"
        },
        { text: "NO", value: "number", width: "80px" },
        {
          text: "JABATAN",
          sortable: true,
          value: "position_name",
          width: "110px"
        },
        {
          text: "PANGKAT",
          sortable: true,
          value: "official_rank",
          width: "110px"
        },
        {
          text: "NAMA",
          sortable: true,
          value: "official_name",
          width: "100px"
        },
        {
          text: "SUB SATUAN KERJA",
          sortable: true,
          value: "sub_work_unit_name",
          width: "170px"
        },
        {
          text: "BAGIAN SUB SATUAN KERJA",
          sortable: true,
          value: "part_of_sub_work_unit_name",
          width: "220px"
        },
        {
          text: "NOMINAL PENGAJUAN",
          sortable: true,
          value: "amount_submission",
          width: "170px"
        },
        {
          text: "NOMINAL PEMERIKSAAN",
          sortable: true,
          value: "amount_checking",
          align:
            localStorage.getLocalStorage("User_Role").toLowerCase() ==
              "atasan operasional" ||
            localStorage.getLocalStorage("User_Role").toLowerCase() == "atasan"
              ? ""
              : " d-none",
          width: "210px"
        },
        {
          text: "NOMINAL PERSETUJUAN",
          sortable: true,
          value: "amount_approval",
          align:
            localStorage.getLocalStorage("User_Role").toLowerCase() == "atasan"
              ? ""
              : " d-none",
          width: "200px"
        },
        {
          text: "KETERANGAN",
          sortable: true,
          value: "official_desc",
          width: "100px"
        },
        {
          text: "EDIT",
          value: "aksi",
          sortable: false,
          width: "110px"
        }
      ];
    },

    getHeadersHistoryOfficials() {
      return [
        {
          text: "Id",
          sortable: false,
          value: "official_id",
          align: " d-none"
        },
        { text: "NO", value: "number", width: "80px" },
        {
          text: "PROYEK",
          sortable: true,
          value: "project_name"
        },
        {
          text: "NOMINAL PROYEK",
          sortable: true,
          value: "project_amount"
        },
        {
          text: "JABATAN",
          sortable: true,
          value: "position_name"
        },
        {
          text: "PANGKAT",
          sortable: true,
          value: "official_rank"
        },
        {
          text: "NAMA",
          sortable: true,
          value: "official_name"
        },
        {
          text: "NOMINAL",
          sortable: true,
          value: "amount"
        },
        {
          text: "KETERANGAN",
          sortable: true,
          value: "official_desc"
        }
      ];
    },

    getSubOrganizationalUnitCombobox() {
      if (localStorage.getLocalStorage("User_Role") == "atasan") {
        return [
          {
            sub_organizational_unit_id: "HO",
            sub_organizational_unit_name: "HO"
          },
          {
            sub_organizational_unit_id: "Satker",
            sub_organizational_unit_name: "SATKER"
          },
          {
            sub_organizational_unit_id: "Non-Satker",
            sub_organizational_unit_name: "NON-SATKER"
          }
        ];
      } else {
        return [
          {
            sub_organizational_unit_id: "Satker",
            sub_organizational_unit_name: "SATKER"
          },
          {
            sub_organizational_unit_id: "Non-Satker",
            sub_organizational_unit_name: "NON-SATKER"
          }
        ];
      }
    },

    loadDate: function() {
      this.date_submission = new Date().toISOString().substr(0, 10);
      this.formInputNotClear.date_submission = this.formatDate(
        new Date().toISOString().substr(0, 10)
      );
    },

    loadDateModified: function() {
      this.date = new Date().toISOString().substr(0, 10);
      this.formInputNotClear.date = this.formatDate(
        new Date().toISOString().substr(0, 10)
      );
    },

    formatDate(date) {
      if (!date) return null;

      var arr = date.split("-"),
        year = arr[0],
        month = arr[1],
        day = arr[2];
      return `${day}-${month}-${year}`;
    },

    parseDate: function(date) {
      if (!date) return null;

      var arr = date.split("/"),
        day = arr[0],
        month = arr[1],
        year = arr[2];
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    onChangeDate(item) {
      this.$refs.date_submission_form.save(item);
      this.data_submission_form_date.date_submission = item;
    },

    onChangeSearchHistoryAmountFrom(item) {
      this.search_history.amount_range_from = item;
    },

    onChangeSearchHistoryAmountTo(item) {
      this.search_history.amount_range_to = item;
    },

    createDataSubmissionForm() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "C",
          Token: "C",
          UID: localStorage.getLocalStorage("UID"),
          Route: "DEFAULT",
          workgroup_id: this.add_submission_form.workgroup_id,
          organizational_unit_id: this.add_submission_form
            .organizational_unit_id,
          sub_organizational_unit: this.add_submission_form
            .sub_organizational_unit,
          work_unit_id: this.add_submission_form.work_unit_id,
          sub_work_unit_id: this.add_submission_form.sub_work_unit_id,
          part_of_sub_work_unit_id: this.add_submission_form
            .part_of_sub_work_unit_id,
          project_id: this.add_submission_form.project_id,
          activity_id: this.add_submission_form.activity_id,
          date_submission: this.data_submission_form_date.date_submission,
          submission_desc: this.add_submission_form.submission_desc,
          submission_type: "KKK",
          submission_permission: "ADM",
          cashbank_type: this.add_submission_form.cashbank_type,
          cashbank_permission: "AP",
          transaction_type: "",
          amount: this.add_submission_form.amount,
          payment_status:
            this.add_submission_form_payment_status.payment_status != ""
              ? this.add_submission_form_payment_status.payment_status
              : this.add_submission_form.payment_status,
          created_by: localStorage.getLocalStorage("User_Fullname"),
          status_cashing: 0,
          status: 1
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/SubmissionForm",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            if (response.status == 1000) {
              Swal.fire({
                title: "",
                text: "BERHASIL MENAMBAH DATA",
                icon: "success",
                heightAuto: true,
                timer: 1500
              });
            } else {
              Swal.fire({
                title: "",
                text: response.message,
                icon: "info",
                heightAuto: true,
                timer: 1500
              });
            }
            this.create_data_submission_form = response.data;
            this.submitted = true;
            this.save("add_submission_form");
            this.asyncCreateDataOfficials();
          },
          err => {
            err;
          }
        );
      });
    },

    createDataOfficials() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "C",
          Token: "C",
          UID: localStorage.getLocalStorage("UID"),
          Route: "CREATE_SOCIALIZATION",
          organizational_unit_id: this.add_submission_form
            .organizational_unit_id,
          work_unit_id: this.add_submission_form.work_unit_id,
          sub_work_unit_id: this.add_submission_form.sub_work_unit_id,
          part_of_sub_work_unit_id: JSON.stringify(this.add_submission_form.part_of_sub_work_unit_id),
          workgroup_id: this.add_submission_form.workgroup_id,
          submission_permission: "ADM",
          created_by: localStorage.getLocalStorage("User_Fullname"),
          date_submission: this.data_submission_form_date.date_submission,
          status_submission: 0,
          status_checking: 0,
          status_approval: 0,
          status: 1
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Master/Officials",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.create_data_officials = response.data;
            this.submitted = true;
            this.save("add_officials");
            this.loadDataOfficials();
          },
          err => {
            err;
          }
        );
      });
    },

    updateDataSubmissionForm() {
      return new Promise(resolve => {
        if (
          this.editedItem.payment_status != "100%" &&
          this.editedItem.payment_status != "80%" &&
          this.editedItem.payment_status != "20%"
        ) {
          this.paymentStatus = this.editedItemPaymentStatus.payment_status;
        } else {
          this.paymentStatus = this.editedItem.payment_status;
        }

        var mydata = {
          Trigger: "U",
          Token: "U",
          UID: localStorage.getLocalStorage("UID"),
          Route: "DEFAULT",
          submission_number: this.editedItem.submission_number,
          workgroup_id: this.editedItem.workgroup_id,
          organizational_unit_id: this.editedItem.organizational_unit_id,
          sub_organizational_unit: this.editedItem.sub_organizational_unit,
          work_unit_id: this.editedItem.work_unit_id,
          sub_work_unit_id: this.editedItem.sub_work_unit_id,
          part_of_sub_work_unit_id: this.editedItem.part_of_sub_work_unit_id,
          project_id: this.editedItem.project_id,
          activity_id: this.editedItem.activity_id,
          date_submission: this.data_submission_form_date.date_submission,
          submission_desc: this.editedItem.submission_desc,
          submission_type: "KKK",
          submission_permission: "ADM",
          submission_financing: 0,
          transaction_type: "",
          amount: this.editedItem.amount,
          payment_status: this.paymentStatus,
          modified_by: localStorage.getLocalStorage("User_Fullname"),
          date_modified: this.data_submission_form_date.date,
          checking_status: this.editedItem.checking_status,
          approval_status: this.editedItem.approval_status,
          allocation_status: this.editedItem.allocation_status,
          status_cashing: 0,
          status: 1
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/SubmissionForm",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            if (response.status == 1000) {
              Swal.fire({
                title: "",
                text: "BERHASIL MEMPERBAHARUI DATA",
                icon: "success",
                heightAuto: true,
                timer: 1500
              });
            } else {
              Swal.fire({
                title: "",
                text: response.message,
                icon: "info",
                heightAuto: true,
                timer: 1500
              });
            }
            this.update_data_submission_form = response.data;
            this.submitted = true;
            this.save("edit_submission_form");
            this.loadDataSubmissionForm();
          },
          err => {
            err;
          }
        );
      });
    },

    updateDataSubmissionFormAmount() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "U",
          Token: "U",
          UID: localStorage.getLocalStorage("UID"),
          Route: "UPDATE_AMOUNT",
          submission_number: this.submissionNumber,
          amount: this.submissionAmount,
          status: 1
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/SubmissionForm",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.update_data_submission_form_amount = response.data;
            this.submitted = true;
            this.loadDataSubmissionForm();
          },
          err => {
            err;
          }
        );
      });
    },

    updateDataSubmissionFormInfo() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "U",
          Token: "U",
          UID: localStorage.getLocalStorage("UID"),
          Route: "UPDATED_INFO",
          submission_number: this.editedSubmissionInfo.submission_number,
          submission_info: this.editedSubmissionInfo.submission_info
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/SubmissionForm",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            if (response.status == 1000) {
              Swal.fire({
                title: "",
                text: "BERHASIL MEMPERBAHARUI DATA",
                icon: "success",
                heightAuto: true,
                timer: 1500
              });
            } else {
              Swal.fire({
                title: "",
                text: response.message,
                icon: "info",
                heightAuto: true,
                timer: 1500
              });
            }
            this.update_data_submission_form = response.data;
            this.submitted = true;
            this.save("edit_submission_form_info");
            this.loadDataSubmissionForm();
          },
          err => {
            err;
          }
        );
      });
    },

    updateDataOfficials() {
      return new Promise(resolve => {
        let amountSubmission = 0;
        let amountChecking = 0;
        let amountApproval = 0;

        if (
          localStorage.getLocalStorage("User_Role").toLowerCase() ==
            "operasional" &&
          this.amountApprovalOfficials == 0
        ) {
          amountSubmission = this.editedItemOfficials.amount_approval;
          amountChecking = this.editedItemOfficials.amount_approval;
          amountApproval = this.editedItemOfficials.amount_approval;
        } else if (
          localStorage.getLocalStorage("User_Role").toLowerCase() == "atasan operasional"
        ) {
          amountSubmission = this.editedItemOfficials.amount_submission;
          amountChecking = this.editedItemOfficials.amount_approval;
          amountApproval = this.editedItemOfficials.amount_approval;
        } else if (
          localStorage.getLocalStorage("User_Role").toLowerCase() == "atasan"
        ) {
          amountSubmission = this.editedItemOfficials.amount_submission;
          amountChecking = this.editedItemOfficials.amount_checking;
          amountApproval = this.editedItemOfficials.amount_approval;
        } else {
          amountSubmission = this.editedItemOfficials.amount_submission;
          amountChecking = this.editedItemOfficials.amount_checking;
          amountApproval = this.editedItemOfficials.amount_approval;
        }

        var mydata = {
          Trigger: "U",
          Token: "U",
          UID: localStorage.getLocalStorage("UID"),
          Route: "DEFAULT",
          official_id: this.editedItemOfficials.official_id,
          submission_number: this.submissionNumber,
          organizational_unit_id: this.organizationalUnitId,
          work_unit_id: this.workUnitId,
          sub_work_unit_id: this.editedItemOfficials.sub_work_unit_id,
          part_of_sub_work_unit_id: this.editedItemOfficials
            .part_of_sub_work_unit_id,
          position_id: this.editedItemOfficials.position_id,
          official_name: this.editedItemOfficials.official_name,
          official_desc: this.editedItemOfficials.official_desc,
          official_rank: this.editedItemOfficials.official_rank,
          amount_submission: amountSubmission,
          amount_checking: amountChecking,
          amount_approval: amountApproval,
          modified_by: localStorage.getLocalStorage("User_Fullname"),
          date_modified: this.data_submission_form_date.date,
          status_submission:
            localStorage.getLocalStorage("User_Role").toLowerCase() ==
              "operasional"
              ? 1
              : this.editedItemOfficials.status_submission,
          status_checking:
            localStorage.getLocalStorage("User_Role").toLowerCase() ==
            "atasan operasional"
              ? 1
              : this.editedItemOfficials.status_checking,
          status_approval: 0,
          status: 1
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Master/Officials",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            if (response.status == 1000) {
              Swal.fire({
                title: "",
                text: "BERHASIL MEMPERBAHARUI DATA",
                icon: "success",
                heightAuto: true,
                timer: 1500
              });
            } else {
              Swal.fire({
                title: "",
                text: response.message,
                icon: "info",
                heightAuto: true,
                timer: 1500
              });
            }
            this.update_data_officials = response.data;
            this.submitted = true;
            this.add_officials.official_id = "";
            this.save("edit_officials");
            this.loadDataOfficials();
          },
          err => {
            err;
          }
        );
      });
    },

    bulkDeleteDataOfficials() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "D",
          Token: "D",
          UID: localStorage.getLocalStorage("UID"),
          Route: "BULK",
          official_id: JSON.stringify(this.add_officials.official_id),
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Master/Officials",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            if (response.status == 1000) {
              Swal.fire({
                title: "",
                text: "BERHASIL MEMPERBAHARUI DATA",
                icon: "success",
                heightAuto: true,
                timer: 1500
              });
            } else {
              Swal.fire({
                title: "",
                text: response.message,
                icon: "info",
                heightAuto: true,
                timer: 1500
              });
            }
            this.update_data_officials = response.data;
            this.submitted = true;
            this.add_officials.official_id = "";
            this.save("edit_officials");
            this.loadDataOfficials();
          },
          err => {
            err;
          }
        );
      });
    },

    updateDataOfficialsPaidStatus() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "U",
          Token: "U",
          UID: localStorage.getLocalStorage("UID"),
          Route: "PAID_STATUS_BY_OFFICIAL_ID",
          official_id: JSON.stringify(this.add_officials.official_id),
          paid_status: 1
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Master/Officials",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            if (response.status == 1000) {
              Swal.fire({
                title: "",
                text: "BERHASIL MEMPERBAHARUI DATA",
                icon: "success",
                heightAuto: true,
                timer: 1500
              });
            } else {
              Swal.fire({
                title: "",
                text: response.message,
                icon: "info",
                heightAuto: true,
                timer: 1500
              });
            }
            this.update_data_officials = response.data;
            this.submitted = true;
            this.save("edit_officials");
            this.loadDataOfficials();
          },
          err => {
            err;
          }
        );
      });
    },

    updateDataSubmissionFormCashSubmissionStatus() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "U",
          Token: "U",
          UID: localStorage.getLocalStorage("UID"),
          Route: "CASH_SUBMISSION_STATUS",
          submission_number: this.submissionNumber,
          cash_submission_status: 1
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/SubmissionForm",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            if (response.status == 1000) {
              Swal.fire({
                title: "",
                text: "BERHASIL MEMPERBAHARUI DATA",
                icon: "success",
                heightAuto: true,
                timer: 1500
              });
            } else {
              Swal.fire({
                title: "",
                text: response.message,
                icon: "info",
                heightAuto: true,
                timer: 1500
              });
            }
            this.update_data_submission_form = response.data;
            this.submitted = true;
            this.save("edit_submission_form");
            this.loadDataSubmissionForm();
            this.dialogShowOfficialsTable = false;
          },
          err => {
            err;
          }
        );
      });
    },

    updateDataSubmissionFormChecking() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "U",
          Token: "U",
          UID: localStorage.getLocalStorage("UID"),
          Route: "CHECKING",
          submission_number: this.submissionNumber,
          checking_by: localStorage.getLocalStorage("User_Fullname"),
          date_checking: this.data_submission_form_date.date,
          money_status: "C",
          checking_status: 1
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/SubmissionForm",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            if (response.status == 1000) {
              Swal.fire({
                title: "",
                text: "BERHASIL MEMPERBAHARUI DATA",
                icon: "success",
                heightAuto: true,
                timer: 1500
              });
            } else {
              Swal.fire({
                title: "",
                text: response.message,
                icon: "info",
                heightAuto: true,
                timer: 1500
              });
            }
            this.update_data_submission_form = response.data;
            this.submitted = true;
            this.save("edit_submission_form");
            this.asyncUpdateDataSubmissionFormStatusChecking();
            this.dialogShowOfficialsTable = false;
          },
          err => {
            err;
          }
        );
      });
    },

    updateDataSubmissionFormApproval() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "U",
          Token: "U",
          UID: localStorage.getLocalStorage("UID"),
          Route: "APPROVAL",
          submission_number: this.submissionNumber,
          workgroup_id: this.workgroupId,
          submission_type: this.submissionType,
          cashbank_type: this.cashbankType,
          cashbank_permission: this.cashbankPermission,
          cashbank_date: this.data_submission_form_date.date,
          approval_by: localStorage.getLocalStorage("User_Fullname"),
          money_status: "A",
          approval_status: 1,
          allocation_status: 1
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/SubmissionForm",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            if (response.status == 1000) {
              Swal.fire({
                title: "",
                text: "BERHASIL MEMPERBAHARUI DATA",
                icon: "success",
                heightAuto: true,
                timer: 1500
              });
            } else {
              Swal.fire({
                title: "",
                text: response.message,
                icon: "info",
                heightAuto: true,
                timer: 1500
              });
            }
            this.update_data_submission_form = response.data;
            this.submitted = true;
            this.save("edit_submission_form");
            this.loadDataSubmissionForm();
            this.dialogShowOfficialsTable = false;
          },
          err => {
            err;
          }
        );
      });
    },

    updateDataSubmissionFormStatusChecking() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "U",
          Token: "U",
          UID: localStorage.getLocalStorage("UID"),
          Route: "STATUS_CHECKING",
          submission_number: this.submissionNumber,
          status_checking: 1
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Master/Officials",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.update_data_officials = response.data;
            this.submitted = true;
            this.save("edit_officials");
            this.loadDataSubmissionForm();
          },
          err => {
            err;
          }
        );
      });
    },

    updateDataSubmissionFormMoneyStatus() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "U",
          Token: "U",
          UID: localStorage.getLocalStorage("UID"),
          Route: "MONEY_STATUS",
          submission_number: this.submissionNumber,
          money_status: this.moneyStatus
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/SubmissionForm",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.update_data_submission_form = response.data;
            this.submitted = true;
            this.save("edit_money_status");
          },
          err => {
            err;
          }
        );
      });
    },

    deleteDataSubmissionForm() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "D",
          Token: "D",
          UID: localStorage.getLocalStorage("UID"),
          Route: "DEFAULT",
          submission_number: this.deletedItem.submission_number
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/SubmissionForm",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            if (response.status == 1000) {
              Swal.fire({
                title: "",
                text: "BERHASIL MENGHAPUS DATA",
                icon: "success",
                heightAuto: true,
                timer: 1500
              });
            } else {
              Swal.fire({
                title: "",
                text: response.message,
                icon: "info",
                heightAuto: true,
                timer: 1500
              });
            }
            this.delete_data_submission_form = response.data;
            this.submitted = true;
            this.data_submission_form.splice(this.deletedIndex, 1);
            this.closeDelete();
          },
          err => {
            err;
          }
        );
      });
    },

    deleteDataOfficials() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "D",
          Token: "D",
          UID: localStorage.getLocalStorage("UID"),
          Route: "DEFAULT",
          official_id: this.deletedItemOfficials.official_id,
          submission_number: this.submissionNumber,
          organizational_unit_id: this.organizationalUnitId,
          work_unit_id: this.workUnitId
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Master/Officials",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            if (response.status == 1000) {
              Swal.fire({
                title: "",
                text: "BERHASIL MENGHAPUS DATA",
                icon: "success",
                heightAuto: true,
                timer: 1500
              });
            } else {
              Swal.fire({
                title: "",
                text: response.message,
                icon: "info",
                heightAuto: true,
                timer: 1500
              });
            }
            this.delete_data_officials = response.data;
            this.submitted = true;
            this.data_officials.splice(this.deletedIndex, 1);
            this.amount_approval = 0;
            for (let i = 0; i < this.data_officials.length; i++) {
              this.amount_approval += parseInt(
                this.data_officials[i].amount_approval
              );
            }
            this.submissionAmount = this.amount_approval;
            this.closeDeleteOfficials();
          },
          err => {
            err;
          }
        );
      });
    },

    dataSubmissionForm() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "DEFAULT_ALL",
          submission_type: "KKK",
          submission_permission: "ADM"
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/SubmissionForm",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_submission_form = response.data;
            this.loadingTable = false;
          },
          err => {
            err;
          }
        );
      });
    },

    dataOfficials() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "DEFAULT_ALL",
          submission_number: this.submissionNumber,
          organizational_unit_id: this.organizationalUnitId,
          work_unit_id: this.workUnitId
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Master/Officials",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_officials = response.data;
            this.amount_approval = 0;
            this.paidStatusValue = 0;

            for (let i = 0; i < this.data_officials.length; i++) {
              this.amount_approval += parseInt(
                this.data_officials[i].amount_approval
              );

              this.paidStatusValue += parseInt(
                this.data_officials[i].paid_status
              );

              if (this.paidStatusValue == 0) {
                if (this.paidStatusValue == this.data_officials.length) {
                  this.checkPaidStatusValue = this.paidStatusValue;
                } else {
                  this.checkPaidStatusValue = 0;
                }
              } else if (this.paidStatusValue > 0) {
                if (this.paidStatusValue == this.data_officials.length) {
                  this.checkPaidStatusValue = this.paidStatusValue;
                } else {
                  this.checkPaidStatusValue = 0;
                }
              }
            }

            this.submissionAmount = this.amount_approval;

            if (this.data_officials.length > 1) {
              if (
                this.paidStatusValue > 0 &&
                this.paidStatusValue < this.data_officials.length
              ) {
                this.moneyStatus = "R";
              } else if (this.paidStatusValue == this.data_officials.length) {
                this.moneyStatus = "HO";
              } else {
                this.moneyStatus = "";
              }
            } else if (this.data_officials.length == 1) {
              if (this.paidStatusValue == 0) {
                this.moneyStatus = "R";
              } else if (this.paidStatusValue == 1) {
                this.moneyStatus = "HO";
              } else {
                this.moneyStatus = "";
              }
            } else if (this.data_officials.length < 1) {
              this.moneyStatus = "";
            }

            this.loadingTable = false;
            this.asyncUpdateDataSubmissionFormMoneyStatus();
          },
          err => {
            err;
          }
        );
      });
    },

    dataHistoryOfficials() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "HISTORY_OFFICIALS_SOS_ADM",
          amount_range_from: this.search_history.amount_range_from,
          amount_range_to: this.search_history.amount_range_to,
          project_id: this.projectId,
          submission_permission: "ADM"
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Master/Officials",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_history_officials = response.data;
            this.seenButtonPrint = true;
            this.loadingTable = false;
          },
          err => {
            err;
          }
        );
      });
    },

    dataSubWorkUnits_Combobox() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "COMBOBOX"
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Master/SubWorkUnits",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_sub_work_units_combobox = response.data;
          },
          err => {
            err;
          }
        );
      });
    },

    dataPartOfSubWorkUnits_Combobox() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "COMBOBOX",
          sub_work_unit_id: this.formInput.sub_work_unit_id
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Master/PartOfSubWorkUnits",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_part_of_sub_work_units_combobox = response.data;
          },
          err => {
            err;
          }
        );
      });
    },

    dataEscrowPaymentAccepted() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "ESCROW_PAYMENT_ACCEPTED",
          payment_accepted: "E",
          status_receipt: 1,
          status_escrow_accepted: 0
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/BankTransaction",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_escrow_notification = response.data;
            this.count_escrow_notification =
              this.user_role.toLowerCase() === "atasan keuangan"
                ? response.data.length
                : 0;
          },
          err => {
            err;
          }
        );
      });
    },

    dataBankGuaranteeNotification() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "NOTIFICATION_BANK_GUARANTEE",
          status_cashing: 0
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/BankGuarantee",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_bank_guarantee_notification = response.data;
            this.count_bank_guarantee_notification =
              this.user_role.toLowerCase() === "atasan"
                ? response.data.length
                : 0;
          },
          err => {
            err;
          }
        );
      });
    },

    dataSubmissionFormNotification() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "NOTIFICATION_FOR_BANK_GUARANTEE",
          submission_type: "BGOI",
          status_cashing: 0
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/SubmissionForm",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_submission_form_finance_notification = response.data;
            this.count_submission_form_finance_notification =
              this.user_role.toLowerCase() === "atasan"
                ? response.data.length
                : 0;
          },
          err => {
            err;
          }
        );
      });
    },

    dataWorkgroup_Combobox() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "COMBOBOX"
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Master/Workgroup",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_workgroup_combobox = response.data;
          },
          err => {
            err;
          }
        );
      });
    },

    dataOrganizationalUnits_Combobox() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "COMBOBOX"
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Master/OrganizationalUnits",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_organizational_units_combobox = response.data;
          },
          err => {
            err;
          }
        );
      });
    },

    dataWorkUnits_Combobox() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          sub_organizational_unit: this.itemSubOrganizationalUnit,
          Route: "COMBOBOX",
          organizational_unit_id: this.formInput.organizational_unit_id
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Master/WorkUnits",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_work_units_combobox = response.data;
          },
          err => {
            err;
          }
        );
      });
    },

    dataProject_Combobox() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "COMBOBOX",
          workgroup_id: this.formInput.workgroup_id
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Master/Project",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_project_combobox = response.data;
          },
          err => {
            err;
          }
        );
      });
    },

    dataPositions_Combobox() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          sub_organizational_unit: this.itemSubOrganizationalUnit,
          work_unit_id: this.itemWorkUnitId,
          Route: "COMBOBOX"
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Master/Positions",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_positions_combobox = response.data;
          },
          err => {
            err;
          }
        );
      });
    },

    dataActivities_Combobox() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "COMBOBOX",
          sub_organizational_unit: this.sub_organzational_unit_activities
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Master/Activities",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_activities_combobox = response.data;
          },
          err => {
            err;
          }
        );
      });
    },

    dataNotification(item) {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "NOTIFICATION_MOBILE",
          submission_number: item.submission_number,
          submission_permission: item.submission_permission
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/SubmissionForm",
          qs.stringify(mydata),
          contentType,
          response => {
            let tokenId = '';
            resolve(response.data);
            this.data_token = response.data;
            for (let i = 0; i < this.data_token.length; i++) {
              tokenId = this.data_token[i];
            }
            this.sendNotification(tokenId);
          },
          err => {
            err;
          }
        );
      });
    },

    sendNotification(item) {
      let tokenId = '';
      tokenId = item.token_id.split(',');
      const auth_token = 'key=AAAAMQjsjRo:APA91bFy3l1_o6QO94xjOybDBkgQiTq4tpVLYBfcIPDWmVSVjKqwgFgrawhTHOmLZCdqEcHcVtT8KkIHN1y-4g7XGEGVEFQWZ1I92-HVlwI5Y5_121h-7BCBMsJCwgNCAPSriudLOGIG';

      const instance = axios.create({
        baseURL: 'https://fcm.googleapis.com/',
        headers: {
          'Authorization': auth_token,
          'Content-Type': 'application/json'
        }
      });

      const data = {
        "registration_ids": tokenId,
        "priority": "high",
        "soundName": "default",
        "notification": {
          "title": "Approval",
          "body": `Approval data dengan No Pengajuan ${item.submission_number}`
        }
      };

      instance.post('fcm/send', data)
        .then(response => {
          if (response.status == 200) {
            Swal.fire({
              title: "",
              text: "BERHASIL MENGIRIM NOTIFIKASI",
              icon: "success",
              heightAuto: true,
              timer: 1500
            });
          } else {
            Swal.fire({
              title: "",
              text: response.message,
              icon: "info",
              heightAuto: true,
              timer: 1500
            });
          }
        })
        .catch(error => {
          console.log(error);
        });
    },

    async load() {
      Promise.all([
        //await this.dataEscrowPaymentAccepted(),
        //await this.dataBankGuaranteeNotification(),
        //await this.dataSubmissionFormNotification(),
        await this.dataSubmissionForm()
      ]).then(function(results) {
        results;
      });
    },

    async asyncCreateDataOfficials() {
      Promise.all([await this.createDataOfficials()]).then(function(results) {
        results;
      });
    },

    async asyncUpdateDataSubmissionFormStatusChecking() {
      Promise.all([await this.updateDataSubmissionFormStatusChecking()]).then(
        function(results) {
          results;
        }
      );
    },

    async asyncUpdateDataSubmissionFormMoneyStatus() {
      Promise.all([
        await this.updateDataSubmissionFormMoneyStatus(),
        await this.updateDataSubmissionFormAmount()
      ]).then(function(results) {
        results;
      });
    },

    async loadDataSubmissionForm() {
      Promise.all([await this.dataSubmissionForm()]).then(function(results) {
        results;
      });
    },

    async loadDataOfficials() {
      Promise.all([await this.dataOfficials()]).then(function(results) {
        results;
      });
    },

    async loadCombobox() {
      Promise.all([
        await this.dataWorkgroup_Combobox(),
        await this.dataOrganizationalUnits_Combobox(),
        await this.dataWorkUnits_Combobox(),
        await this.dataSubWorkUnits_Combobox(),
        await this.dataProject_Combobox(),
        await this.dataActivities_Combobox()
      ]).then(function(results) {
        results;
      });
    },

    async loadComboboxOfficials() {
      Promise.all([await this.dataPositions_Combobox()]).then(function(
        results
      ) {
        results;
      });
    },

    officialsSelect(item) {
      this.submissionNumber = item.submission_number;
      this.organizationalUnitId = item.organizational_unit_id;
      this.workUnitId = item.work_unit_id;
      this.data_history_officials_print.project_name = item.project_name;
      this.data_history_officials_print.project_amount = item.project_amount;
      this.submissionAmount = this.amount;
      this.cashSubmissionStatus = item.cash_submission_status;
      this.checkingStatus = item.checking_status;
      this.approvalStatus = item.approval_status;

      this.dateSubmission = item.date_submission;
      this.workgroupId = item.workgroup_id;
      this.submissionType = item.submission_type;
      this.cashbankType = item.cashbank_type;
      this.cashbankPermission = item.cashbank_permission;
      this.projectId = item.project_id;
      this.submissionDesc = item.submission_desc;
      this.submissionAmountToVoucher = item.amount;
      this.createdBy = item.created_by;

      this.dataOfficials();
      this.dialogShowOfficialsTable = true;
    },

    showHistoryOfficials(item) {
      this.seenHistory = item;
      this.seenButtonPrint = false;
      this.dialogShowOfficialsTable = item;

      if (!item) {
        this.search_history.amount_range_from = 0;
        this.search_history.amount_range_to = 0;
      }
    },

    createOfficials() {
      this.editedIndex = -2;
      this.dialogCreateOfficials = true;
    },

    editItem(item) {
      this.editedIndex = this.data_submission_form.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItemPaymentStatus = Object.assign({}, item);
      this.loadCombobox();
      this.titleFromSubOrganizationalUnit(item.sub_organizational_unit);
      if (
        this.editedItem.payment_status != "100%" &&
        this.editedItem.payment_status != "80%" &&
        this.editedItem.payment_status != "20%"
      ) {
        this.editedItem.payment_status = "Lain-lain";
        this.seen = this.editedItem.payment_status;
      }
      this.dialog = true;
    },

    editItemOfficials(item) {
      this.editedIndex = -3;
      this.editedItemOfficials = Object.assign({}, item);
      this.loadComboboxOfficials();
      this.dialogCreateOfficials = true;
      this.amountApprovalOfficials = item.amount_approval;
    },

    editItemSubmissionInfo(item) {
      this.editedIndex = -4;
      this.editedSubmissionInfo = Object.assign({}, item);
      this.dialogSubmissionInfo = true;
    },

    deleteItem(item) {
      this.deletedIndex = this.data_submission_form.indexOf(item);
      this.deletedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemOfficials(item) {
      this.deletedIndex = this.data_officials.indexOf(item);
      this.deletedItemOfficials = Object.assign({}, item);
      this.dialogDeleteOfficials = true;
    },

    deleteItemConfirm() {
      this.data_submission_form.splice(this.deletedIndex, 1);
      this.closeDelete();
    },

    deleteItemConfirmOfficials() {
      this.data_officials.splice(this.deletedIndex, 1);
      this.closeDeleteOfficials();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.add_submission_form.workgroup_id = "";
        this.add_submission_form.organizational_unit_id = "";
        this.add_submission_form.sub_organizational_unit = "";
        this.add_submission_form.work_unit_id = "";
        this.add_submission_form.sub_work_unit_id = "";
        this.add_submission_form.sub_work_unit_name = "";
        this.add_submission_form.part_of_sub_work_unit_id = "";
        this.add_submission_form.part_of_sub_work_unit_name = "";
        this.add_submission_form.project_id = "";
        this.add_submission_form.activity_id = "";
        this.add_submission_form.submission_desc = "";
        this.add_submission_form.amount = 0;
        this.add_submission_form.payment_status = "";
        this.add_submission_form_payment_status.payment_status = "";
        this.seen = this.formInput.payment_status;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedItemPaymentStatus = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeMoneyStatus() {
      this.dialogMoneyStatus = false;
      this.add_submission_form.money_status = "";
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    },

    closeOfficials() {
      this.dialogCreateOfficials = false;
      this.$nextTick(() => {
        this.add_officials.position_id = "";
        this.add_officials.official_name = "";
        this.add_officials.official_desc = "";
        this.add_officials.official_rank = "";
        this.add_officials.amount_approval = 0;
        this.editedItemOfficials = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeSubmissionInfo() {
      this.dialogSubmissionInfo = false;
      this.editedSubmissionInfo = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.deletedItem = Object.assign({}, this.defaultItem);
        this.deletedIndex = -1;
      });
    },

    closeDeleteOfficials() {
      this.dialogDeleteOfficials = false;
      this.$nextTick(() => {
        this.deletedItem = Object.assign({}, this.defaultItem);
        this.deletedIndex = -1;
      });
    },

    save(formInput) {
      if (formInput == "add_submission_form") {
        if (this.editedIndex > -1) {
          Object.assign(
            this.data_submission_form[this.editedIndex],
            this.add_submission_form
          );
        } else {
          this.data_submission_form.push(this.add_submission_form);
        }

        this.close();
      } else if (formInput == "edit_submission_form") {
        if (this.editedIndex > -1) {
          Object.assign(
            this.data_submission_form[this.editedIndex],
            this.editedItem
          );
        } else {
          this.data_submission_form.push(this.editedItem);
        }

        this.close();
      } else if (formInput == "edit_submission_form_info") {
        if (this.editedIndex > -1) {
          Object.assign(
            this.data_submission_form[this.editedIndex],
            this.editedItem
          );
        } else {
          this.data_submission_form.push(this.editedItem);
        }

        this.closeSubmissionInfo();
      } else if (formInput == "add_officials") {
        if (this.editedIndex > -1) {
          Object.assign(
            this.data_officials[this.editedIndex],
            this.add_officials
          );
        } else {
          this.data_officials.push(this.add_officials);
        }

        this.closeOfficials();
      } else if (formInput == "edit_officials") {
        if (this.editedIndex > -1) {
          Object.assign(this.data_officials[this.editedIndex], this.editedItem);
        } else {
          this.data_officials.push(this.editedItem);
        }

        this.closeOfficials();
      } else if (formInput == "edit_money_status") {
        if (this.editedIndex > -1) {
          Object.assign(
            this.data_submission_form[this.editedIndex],
            this.editedItem
          );
        } else {
          this.data_submission_form.push(this.editedItem);
        }

        this.closeMoneyStatus();
      }
    },

    generateReportAll() {
      this.data_history_officials;
      this.$refs.html2PdfAll.generatePdf();
    },

    initHeders() {
      this.headers = this.getHeaders();
    },

    initHedersOfficials() {
      this.headers_officials = this.getHeadersOfficials();
    },

    initHedersHistoryOfficials() {
      this.headers_history_officials = this.getHeadersHistoryOfficials();
    },

    initSubOrganizationalUnitCombobox() {
      this.data_sub_organizational_unit_combobox = this.getSubOrganizationalUnitCombobox();
    },

    seenDetailPaymentStatus() {
      this.seen = this.formInput.payment_status;
    },

    titleFromSubOrganizationalUnit(item) {
      if (item == "Satker" || item == "Non-Satker") {
        this.seenComboboxWorkUnit = 1;
      } else {
        this.seenComboboxWorkUnit = 0;
      }

      this.title_select = item;
      this.sub_organzational_unit_activities = item;
      this.itemSubOrganizationalUnit = item;
    },

    itemWorkUnits(item) {
      this.itemWorkUnitId = item;
    },

    formatNumber
  }
};
</script>
